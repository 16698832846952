import * as React from "react";
import PropTypes from "prop-types";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import Nav from "react-bootstrap/Nav";

const BlogNav = function BlogNav({ categories, currentCategory }) {
  const { t } = useTranslation();

  return (
    <Nav className="blog pb-2 mb-5 border-bottom">
      <Nav.Item>
        <Nav.Link
          as={Link}
          active={currentCategory.slug === "/blog"}
          key={currentCategory.id}
          to="/blog/"
        >
          {t("blog.nav.all")}
        </Nav.Link>
      </Nav.Item>
      {categories.map(({ node }) => (
        <Nav.Item>
          <Nav.Link
            as={Link}
            active={currentCategory.slug === node.slug}
            key={node.id}
            to={`/blog/${node.slug}`}
          >
            {node.name}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default BlogNav;

BlogNav.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  currentCategory: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};
