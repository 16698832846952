// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-adhoc-jsx": () => import("./../../../src/pages/about-adhoc.jsx" /* webpackChunkName: "component---src-pages-about-adhoc-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-vartios-smart-waste-jsx": () => import("./../../../src/pages/vartios-smart-waste.jsx" /* webpackChunkName: "component---src-pages-vartios-smart-waste-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/BlogCategory.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-vcard-jsx": () => import("./../../../src/templates/Vcard.jsx" /* webpackChunkName: "component---src-templates-vcard-jsx" */)
}

