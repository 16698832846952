import React, { useEffect, useState } from "react";
import "../scss/App.scss";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useI18next } from "gatsby-plugin-react-i18next";
import PreloadFonts from "./PreloadFonts";
import SEO from "./SEO";
import Footer from "./Footer";
import Header from "./Header";
import ScrollTop from "./ScrollTop";
import { GatsbySitePropType, NavigationPropType } from "../lib/PropTypeShapes";
import CookieConsent from "./CookieConsent";

const Layout = function Layout({
  data,
  children,
  className,
  footerClassName,
  location,
}) {
  const [theme, setTheme] = useState(null);
  const { language } = useI18next();

  useEffect(() => {
    setTheme(window.__theme);
    const callback = () => setTheme(window.__theme);
    window.__addCallback(callback);
    return () => {
      window.__removeCallback(callback);
    };
  }, []);

  const {
    contentfulPage,
    contentfulBlogPost,
    contentfulBlogCategory,
    site,
    topNavigation,
    topOffcanvasNavigation,
    legalNavigation,
    linkNavigation,
  } = data;

  const contentfulPageData =
    contentfulPage || contentfulBlogPost || contentfulBlogCategory;

  return (
    <>
      <Helmet>
        <body className={`${className} ${language}`} />
      </Helmet>
      <SEO
        title={
          contentfulPageData.pageTitle ||
          contentfulPageData.title ||
          contentfulPageData.name
        }
        description={contentfulPageData.description}
        siteMetadata={site.siteMetadata}
      />

      <PreloadFonts />

      <Header
        navigation={topNavigation.elements}
        offcanvasNavigation={topOffcanvasNavigation.elements}
        legalNavigation={legalNavigation}
        theme={theme}
      />
      {children}
      <Footer
        className={footerClassName}
        legalNavigation={legalNavigation}
        linkNavigation={linkNavigation}
      />

      <ScrollTop />
      <CookieConsent location={location} />
    </>
  );
};

const contentfulPageData = (props, propName, componentName) => {
  const { contentfulPage, contentfulBlogPost, contentfulBlogCategory } = props;
  // Todo: Also check if contentfulPage or contentfulBlogPost is in right shape
  if (!contentfulPage && !contentfulBlogPost && !contentfulBlogCategory) {
    return new Error(
      `${componentName}: You must provide either a contentfulPage, contentfulBlogPost or contentfulBlogCategory prop`
    );
  }
  return null;
};

Layout.defaultProps = {
  children: null,
  className: "",
  footerClassName: "",
};

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  footerClassName: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    topOffcanvasNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    contentfulPage: contentfulPageData,
    contentfulBlogPost: contentfulPageData,
    contentfulBlogCategory: contentfulPageData,
  }).isRequired,
};

export default Layout;
