import * as React from "react";
import { trackCustomEvent } from "../lib/TrackingHelper";
import { ReactComponent as IconArrowUp } from "../images/icon-arrow_up.svg";

const ScrollTop = function ScrollTop() {
  return (
    <IconArrowUp
      className="scroll-top"
      width="25"
      height="25"
      onClick={(e) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        trackCustomEvent("click", {
          category: "Button",
          label: "Scrolltop",
        });
      }}
    />
  );
};
export default ScrollTop;
