/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import * as React from "react";
import Container from "react-bootstrap/Container";
import "../scss/App.scss";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Row from "react-bootstrap/Row";
import Layout from "../components/Layout";
import {
  ContentfulBlogPostPropType,
  ContentfulPagePropType,
  GatsbySitePropType,
  LocalesPropType,
  NavigationPropType,
} from "../lib/PropTypeShapes";
import BlogPostCard from "../components/BlogPostCard";
import BlogNav from "../components/BlogNav";
import BlogLatestPost from "../components/BlogLatestPost";

const BlogPage = function BlogPage({ data, location }) {
  const latestBlogPost = data.allContentfulBlogPost.edges[0].node;

  return (
    <Layout data={data} location={location}>
      <Helmet />
      <Container as="section">
        {latestBlogPost && latestBlogPost.title && (
          <BlogLatestPost blogPost={latestBlogPost} />
        )}

        <BlogNav
          categories={data.allContentfulBlogCategory.edges}
          currentCategory={{
            id: "1",
            slug: "/blog",
          }}
        />

        <Row xs={1} md={2} className="g-4 mb-2">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <BlogPostCard blogPost={node} key={node.id} />
          ))}
        </Row>
      </Container>
    </Layout>
  );
};

export default BlogPage;

BlogPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    site: GatsbySitePropType,
    topNavigation: NavigationPropType,
    legalNavigation: NavigationPropType,
    linkNavigation: NavigationPropType,
    contentfulPage: ContentfulPagePropType,
    allContentfulBlogPost: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({ node: ContentfulBlogPostPropType })
      ),
    }),
    locales: LocalesPropType,
  }).isRequired,
};

export const query = graphql`
  query BlogPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    legalNavigation: contentfulNavigation(
      slug: { eq: "legal-info" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    linkNavigation: contentfulNavigation(
      slug: { eq: "links" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topNavigation: contentfulNavigation(
      slug: { eq: "top-navigation" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    topOffcanvasNavigation: contentfulNavigation(
      slug: { eq: "top-offcanvas" }
      node_locale: { eq: $language }
    ) {
      ...ContentFulNavigationFragment
    }
    contentfulPage(slug: { eq: "blog" }, node_locale: { eq: $language }) {
      ...ContentfulPageFragment
    }
    allContentfulBlogCategory(filter: { node_locale: { eq: $language } }) {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
    allContentfulBlogPost(
      filter: { node_locale: { eq: $language }, title: { ne: null } }
      sort: { order: DESC, fields: category___blog_post___image___createdAt }
    ) {
      edges {
        node {
          id
          slug
          title
          createdAt(formatString: "DD.MM.YYYY")
          body {
            childMarkdownRemark {
              excerpt(format: PLAIN, truncate: true)
              html
            }
          }
          category {
            name
            slug
          }
          image {
            id
            internal {
              type
            }
            title
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              height: 810
              breakpoints: [576, 768, 992, 1200]
              quality: 90
            )
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
  }
`;
