import * as React from "react";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import Button from "react-bootstrap/Button";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import { trackCustomEvent } from "../lib/TrackingHelper";

function isBrowser() {
  return typeof window !== "undefined";
}

function getValue(key, defaultValue) {
  return isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue;
}

function setValue(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function useStickyState(defaultValue, key) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue);
  });

  React.useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
}

const CookieConsent = function CookieConsent({ location }) {
  const { t } = useTranslation();

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    "consentCookieHidden"
  );

  const consent = () => {
    document.cookie = "gatsby-gdpr-google-analytics=true";
    if (isBrowser()) {
      initializeAndTrack(location);
    }
    setBannerHidden(true);
    trackCustomEvent("change_consent", {
      status: "accept",
    });
  };

  const dissent = () => {
    setBannerHidden(true);
    trackCustomEvent("change_consent", {
      status: "reject",
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isBrowser() &&
        window.localStorage.getItem("consentCookieHidden") &&
        !bannerHidden && (
          <div
            className="cookie-consent position-fixed bg-white p-4 shadow-sm"
            style={{ bottom: "0", left: 0, width: "385px" }}
          >
            <span>
              <h2>{t("cookieconsent.title")}</h2>
              <p>{t("cookieconsent.message")}</p>
            </span>
            <Button
              variant="text"
              className="text-decoration-underline mb-3"
              onClick={dissent}
            >
              {t("cookieconsent.button.allow_required")}
            </Button>
            <Button variant="primary" onClick={consent}>
              {t("cookieconsent.button")}
            </Button>
          </div>
        )}
    </>
  );
};

CookieConsent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default CookieConsent;
