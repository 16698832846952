import * as React from "react";
import PropTypes from "prop-types";

import { ReactComponent as IconPiggyBank } from "../images/icon-piggy_bank.svg";
import { ReactComponent as IconCalendar } from "../images/icon-calendar.svg";
import { ReactComponent as IconQuote } from "../images/icon-quote.svg";
import { ReactComponent as IconBox } from "../images/icon-box.svg";
import { ReactComponent as IconCloud } from "../images/icon-cloud.svg";
import { ReactComponent as IconEmail } from "../images/icon-email.svg";
import { ReactComponent as IconGroup } from "../images/icon-group.svg";
import { ReactComponent as IconMagnifier } from "../images/icon-magnifier_graph.svg";
import { ReactComponent as IconPhone } from "../images/icon-phone.svg";
import { ReactComponent as IconRobot } from "../images/icon-robot.svg";
import { ReactComponent as IconSmiley } from "../images/icon-smiley.svg";
import { ReactComponent as IconArrowDown } from "../images/icon-arrow_down.svg";

const Icon = function Icon({ name, ...props }) {
  const iconMap = {
    PiggyBank: IconPiggyBank,
    Calendar: IconCalendar,
    Quote: IconQuote,
    Box: IconBox,
    Cloud: IconCloud,
    Email: IconEmail,
    Group: IconGroup,
    MagnifierGraph: IconMagnifier,
    Phone: IconPhone,
    Robot: IconRobot,
    Smiley: IconSmiley,
    ArrowDown: IconArrowDown,
    default: IconRobot,
  };

  const IconComponent = iconMap[name];

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <IconComponent {...props} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
