/* eslint no-underscore-dangle: "off" */
import React, { useEffect, useState } from "react";
import { trackCustomEvent } from "../lib/TrackingHelper";

const ToggleSwitch = function ToggleSwitch() {
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    setTheme(window.__theme);
    const callback = () => setTheme(window.__theme);
    window.__addCallback(callback);
    return () => {
      window.__removeCallback(callback);
    };
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="toggle-switch">
      {theme && (
        <input
          type="checkbox"
          checked={theme === "dark"}
          onChange={(e) => {
            const chosenTheme = e.target.checked ? "dark" : "light";
            window.__setTheme(chosenTheme);
            trackCustomEvent("change_theme", {
              theme: chosenTheme,
            });
          }}
        />
      )}
      {theme && <span className="switch" />}
    </label>
  );
};
export default ToggleSwitch;
